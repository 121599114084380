import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTheme } from '@chakra-ui/react'
import {
  IoRestaurantOutline,
  IoAirplaneOutline,
  IoWifiOutline,
  IoKeyOutline,
  IoBicycleOutline,
  IoWalkOutline,
  IoPaw,
  IoBonfireSharp,
} from 'react-icons/io5'

import { MdOutlineChurch } from 'react-icons/md'

import { FaSwimmingPool } from 'react-icons/fa'

import { FeatureItem, FeatureList, P } from '../ui'
import { v4 } from 'uuid'

function Services() {
  const { t } = useTranslation()

  const ICONS = {
    breakfast: <IoRestaurantOutline color="#015751" size="2rem" />,
    wifi: <IoWifiOutline color="#015751" size="2rem" />,
    swimming: <FaSwimmingPool color="#015751" size="2rem" />,
    bike: <IoBicycleOutline color="#015751" size="2rem" />,
    airplane: <IoAirplaneOutline color="#015751" size="2rem" />,
    parkinglot: <IoKeyOutline color="#015751" size="2rem" />,
    experiences: <IoWalkOutline color="#015751" size="2rem" />,
    chapel: <MdOutlineChurch color="#015751" size="2rem" />,
    pet: <IoPaw color="#015751" size="2rem" />,
    barbecue: <IoBonfireSharp color="#015751" size="2rem" />,
  }

  return (
    <FeatureList columns={{ base: 8, md: 2, lg: 4 }} spacing={{ base: 2, md: 8 }} overflowX="auto">
      {t('index:services.items', { returnObjects: true }).map((service) => (
        <FeatureItem
          key={v4()}
          minWidth="250px"
          direction="row"
          icon={ICONS[service.id]}
          title={service.title}
          desc={service.desc}
        />
      ))}
    </FeatureList>
  )
}

export default Services
