import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticQuery, graphql } from 'gatsby'

import { RoomList, RoomItem, Image } from '../ui'
import { v4 } from 'uuid'

function Rooms(props) {
  const { t } = useTranslation()

  return (
    <StaticQuery
      query={graphql`
        query {
          mastersuite: file(relativePath: { eq: "suites/master-suite/1.jpg" }) {
            ...fixedImage
          }

          juniorsuite: file(relativePath: { eq: "suites/junior-suite/1.jpg" }) {
            ...fixedImage
          }

          standarddouble: file(relativePath: { eq: "suites/standard-double/1.jpg" }) {
            ...fixedImage
          }

          standard: file(relativePath: { eq: "suites/standard/1.jpg" }) {
            ...fixedImage
          }
        }
      `}
      render={(data) => (
        <RoomList spacing={5} {...props}>
          {t('suites:rooms', { returnObjects: true }).map((room) => (
            <RoomItem
              key={v4()}
              as={Link}
              to={room.to}
              title={room.title}
              desc={`${room.desc.substr(0, 99)} ...`}
              characteristics={room.characteristics}
              images={[<Image fixed={[data[room.id].childImageSharp.fixed]} layout="background" />]}
            />
          ))}
        </RoomList>
      )}
    />
  )
}

export default Rooms
