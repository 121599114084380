import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import Layout from '../../components/layout'
import Rooms from '../../components/rooms'
import Services from '../../components/services'

import { Article, Section } from '../../ui'

function SuitesPage() {
  const { t } = useTranslation()

  return (
    <Layout title={t('suites:title')}>
      <Article>
        <Section title={t('suites:title')} subtitle={t('suites:desc')} titleAlign="center">
          <Rooms />
        </Section>

        <Section title={t('index:services.title')} titleAlign="center" background="gray.100">
          <Services />
        </Section>
      </Article>
    </Layout>
  )
}

export default SuitesPage
